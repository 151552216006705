.contact-form {
    text-align: center;
    /* padding: 20px; */
}

.contact-form h2 {
    margin-bottom: 20px;
}

.contact-form iframe {
    border: none;
    width: 100%;
    height: 600px; /* Set a fixed height for the iframe */
}
